import GoogleMap from 'google-map-react'
import kebabCase from 'lodash/kebabCase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down.svg'
import { ReactComponent as Close } from '../images/icon-close-lg.svg'
import { global } from '../styles/global'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Link from './link'
import Text from './text'

const MapStyle = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#D5F4F6',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#EBFAFB',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#00383C',
      },
      {
        lightness: 30,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
]

const Marker = ({ show, title, link, onClick, onClose }) => {
  const markerStyle = css`
    @keyframes pulse {
      0% {
        transform: translate(-50%, 50%) scale(0.95);
        box-shadow: 0 0 0 0 rgba(23, 15, 79, 0.7);
      }

      70% {
        transform: translate(-50%, 50%) scale(1);
        box-shadow: 0 0 0 20px rgba(23, 15, 79, 0);
      }

      100% {
        transform: translate(-50%, 50%) scale(0.95);
        box-shadow: 0 0 0 0 rgba(23, 15, 79, 0);
      }
    }

    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: rgba(23, 15, 79, 1);
    box-shadow: 0 0 0 0 rgba(23, 15, 79, 1);
    border-radius: 100%;
    user-select: none;
    cursor: pointer;
    animation: pulse 2s infinite;
    &:hover {
      z-index: 1;
    }
  `

  return (
    <>
      <div css={markerStyle} />
      <InfoWindow
        title={title}
        link={link}
        onClick={onClick}
        onClose={onClose}
        style={css`
          ${tw`opacity-0 pointer-events-none transition-opacity duration-200 ease-in-out`}
          ${show && tw`opacity-100 pointer-events-auto`}
        `}
      />
    </>
  )
}

Marker.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
}

const InfoWindow = ({ style, title, link, onClick, onClose }) => {
  const windowStyle = css`
    ${tw`absolute left-0 z-10 flex flex-row items-start justify-between p-5 bg-primary-500 w-56 lg:w-72`}
    bottom: calc(100% + 20px);
    &:after {
      top: 100%;
      left: 0%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #170f4f;
      border-width: 10px;
      margin-left: -10px;
      margin-top: -10px;
      transform: rotate(135deg);
    }
  `
  return (
    <div css={[windowStyle, style]}>
      <div css={tw`flex flex-col`}>
        <Text
          content="bolttech"
          style={[global`typography.subtitle`, tw`mb-1 font-bold text-secondary-500`]}
        />
        <Heading headingType="h4" content={title} style={tw`text-white`} />
        <div onClick={() => onClick(title)} aria-hidden="true">
          <Link type="button" css={tw`flex flex-row items-center mt-4`} to={link}>
            <span css={tw`mr-3 font-medium text-white text-opacity-47 text-sm`}>Get in touch</span>
            <Caret css={tw`fill-current text-white text-opacity-47`} />
          </Link>
        </div>
      </div>
      <button type="button" onClick={onClose}>
        <Close />
      </button>
    </div>
  )
}

InfoWindow.propTypes = {
  style: StyleType,
  title: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
}

const getMapBounds = (maps, places) => {
  const bounds = new maps.LatLngBounds()
  places.forEach((place) => {
    bounds.extend(new maps.LatLng(place.location.latitude, place.location.longitude))
  })
  return bounds
}

const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds)
    })
  })
}

const apiIsLoaded = (map, maps, places) => {
  if (places.length > 0) {
    const bounds = getMapBounds(maps, places)
    map.fitBounds(bounds)
    bindResizeListener(map, maps, bounds)
  }
}

const Map = ({ markers, defaultCenter, defaultZoom, onMarkerClick }) => {
  const [mapMarkers, setMapMarkers] = useState([])
  const [center, setCenter] = useState(undefined)

  useEffect(() => {
    setMapMarkers(markers.map((m) => ({ ...m, show: false })))
  }, [markers])

  const toggleMarker = (key) => {
    const index = mapMarkers.findIndex((m) => m.title === key)
    if (index > -1) {
      mapMarkers.forEach((m, i) => {
        mapMarkers[i].show = index === i && !m.show
      })
      const target = mapMarkers[index]
      setCenter(
        target.show ? { lat: target.location.latitude, lng: target.location.longitude } : undefined
      )
      setMapMarkers([...mapMarkers])
    }
  }

  return (
    <div css={tw`h-map-mobile w-full lg:h-map-default`}>
      <GoogleMap
        bootstrapURLKeys={{ key: `${process.env.GATSBY_GOOGLE_MAPS_TOKEN}` }}
        options={{ styles: MapStyle }}
        onChildClick={toggleMarker}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, mapMarkers)}
      >
        {mapMarkers.map((marker) => (
          <Marker
            key={marker.title}
            lat={marker.location.latitude}
            lng={marker.location.longitude}
            show={marker.show}
            title={marker.title}
            link={`#${kebabCase(marker.title)}`}
            onClick={onMarkerClick}
            onClose={() => toggleMarker(marker.title)}
          />
        ))}
      </GoogleMap>
    </div>
  )
}

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object),
  defaultCenter: PropTypes.object,
  defaultZoom: PropTypes.number,
  onMarkerClick: PropTypes.func,
}

Map.defaultProps = {
  defaultCenter: { lat: 15.758, lng: 101.418 },
  defaultZoom: 0,
  onMarkerClick: () => {},
}

export default Map
