import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down-lg.svg'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'

const AccordionTab = ({ style, label, children }) => {
  const [isOpened, setOpen] = useState(false)
  return (
    <div
      css={[
        css`
          ${tw`py-8`}
          &:last-of-type > div > div {
            ${tw`lg:pb-0`}
          }
        `,
        style,
      ]}
    >
      <button
        type="button"
        css={tw`flex flex-row justify-between items-center w-full py-8 -my-8 outline-none focus:outline-none`}
        onClick={() => setOpen(!isOpened)}
      >
        <Heading headingType="h5" content={label} style={tw`font-bold text-primary-500`} />
        <Caret
          css={[
            tw`fill-current text-primary-500 text-opacity-47 transition duration-300 ease-in-out`,
            isOpened &&
              css`
                transform: scaleY(-1);
                ${tw`text-opacity-100`}
              `,
          ]}
        />
      </button>
      <div
        css={[
          tw`max-h-0 overflow-hidden transition-all duration-300 ease-in-out`,
          isOpened && tw`max-h-accordion`,
        ]}
      >
        <div css={tw`py-8 lg:py-20 flex justify-end`}>{children}</div>
      </div>
    </div>
  )
}

AccordionTab.propTypes = {
  style: StyleType,
  label: PropTypes.string,
  children: PropTypes.node,
}

const Accordion = ({ style, children }) => {
  return (
    <div
      css={[
        tw`border-t border-primary-500 border-opacity-10
        divide-y divide-grey divide-opacity-15 `,
        style,
      ]}
    >
      {React.Children.map(children || null, (child) => {
        return (
          <AccordionTab label={child.key}>
            <child.type {...child.props} key={child.key} />
          </AccordionTab>
        )
      })}
    </div>
  )
}

Accordion.propTypes = {
  style: StyleType,
  children: PropTypes.node,
}

export default Accordion
