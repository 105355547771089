import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import ContactTabs from '../../components/contact-tabs'
import HeaderBlock from '../../components/header-block'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import Locations from '../../components/locations'
import Map from '../../components/map'
import CallToAction from '../../components/call-to-action'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'

const LocationsPage = ({ data: { locations }, location }) => {
  const page = useMemo(
    () =>
      flatten(locations || {}, [
        'hero',
        'contactHeader',
        'contactDpHeader',
        'contactDbHeader',
        'mapHeader',
        'demo',
        'enquiry',
      ]),
    [locations]
  )

  const markers = useMemo(() => [...get(page, 'headquarters'), ...get(page, 'branchOffices')], [
    page,
  ])

  const tabs = useMemo(() => {
    return [
      ...(get(page, 'contactDbHeader.titleNode')
        ? [
            {
              title: get(page, 'contactDbHeader.titleNode'),
              description: get(page, 'contactDbHeader.descriptionNode'),
              contacts: get(page, 'contactDb'),
            },
          ]
        : []),
      ...(get(page, 'contactDpHeader.titleNode')
        ? [
            {
              title: get(page, 'contactDpHeader.titleNode'),
              description: get(page, 'contactDpHeader.descriptionNode'),
              contacts: get(page, 'contactDp'),
            },
          ]
        : []),
    ]
  }, [page])

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} location={location}>
      <section id="hero" css={tw`bg-secondary-500`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          style={css`
            h5 {
              ${tw`text-primary-500 text-opacity-77`}
            }
          `}
        />
      </section>
      <CallToAction
        title={get(page, 'enquiry.title')}
        description={get(page, 'enquiry.descriptionNode')}
        callToAction={get(page, 'enquiry.callToAction')}
        theme="navy"
      />
      <section
        id="contact-header"
        css={[
          global`layout.container`,
          tw`flex flex-col items-center justify-center text-center pt-24 pb-20 lg:(max-w-4xl pt-40 pb-32)`,
        ]}
      >
        <HeaderBlock
          title={get(page, 'contactHeader.titleNode')}
          subtitle={get(page, 'contactHeader.subtitleNode')}
          style={tw`items-center`}
        />
      </section>
      <section id="contact-tabs" css={global`layout.desktop-container`}>
        <ContactTabs tabs={tabs} />
      </section>
      <section id="locations" css={tw`bg-background-grey py-24 lg:(pt-80 pb-32 -mt-40)`}>
        <HeaderBlock
          title={get(page, 'mapHeader.titleNode')}
          description={get(page, 'mapHeader.descriptionNode')}
          style={[global`layout.container`, tw`mb-14 lg:(mb-20 items-center)`]}
        />
        <div css={tw`w-11/12 mx-auto`}>
          <Map markers={markers} />
        </div>
        <div css={[global`layout.container`, tw`mt-20 lg:mt-32`]}>
          <Locations
            headquarters={get(page, 'headquarters')}
            offices={get(page, 'branchOffices')}
            headquartersLabel={get(page, 'headquartersLabel')}
            officesLabel={get(page, 'branchOfficesLabel')}
          />
        </div>
      </section>
    </Layout>
  )
}

LocationsPage.propTypes = {
  data: PropTypes.shape({
    locations: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default LocationsPage

export const query = graphql`
  query LocationQuery($locale: String!) {
    locations: datoCmsKrLocation(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      enquiry {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
        }
      }
      contactHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactDpHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactDp {
        label
        contactType
        value
        subtextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactDbHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactDb {
        label
        contactType
        value
        subtextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      mapHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      headquartersLabel
      headquarters {
        title
        location {
          latitude
          longitude
        }
        region
        parentSiteAddressNode {
          childMarkdownRemark {
            html
          }
        }
      }
      branchOfficesLabel
      branchOffices {
        title
        location {
          latitude
          longitude
        }
        region
        parentSiteAddressNode {
          childMarkdownRemark {
            html
          }
        }
      }
      locationFooterNode {
        childMarkdownRemark {
          html
        }
      }
      demo {
        title
        callToAction {
          label
          link
        }
      }
    }
  }
`
