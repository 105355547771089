import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { HTMLType, RequiredLinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Text from './text'
import { min } from '../utils/breakpoints'
import { global } from '../styles/global'

const CallToAction = ({ title, description, callToAction, theme, style }) => {
  const themeStyle = {
    navy: {
      background: tw`bg-primary-500`,
      title: tw`text-white`,
      description: tw`text-white`,
      button: 'yellow-cyan',
    },
    cyan: {
      background: tw`bg-secondary-500`,
      title: tw`text-primary-500`,
      description: tw`text-primary-500`,
      button: 'navy-white',
    },
  }

  return (
    <div css={[themeStyle[theme].background, style]}>
      <div
        css={[
          global`layout.container`,
          css`
            ${tw`flex flex-col items-start py-8
            lg:(flex-row items-center justify-between py-0)`}
            ${min.lg} {
              padding: 2.375rem 0;
            }
          `,
        ]}
      >
        <div css={tw`lg:mr-24`}>
          <Heading
            headingType="h5"
            content={title}
            style={[themeStyle[theme].title, tw`font-bold`]}
          />
          <Text content={description} style={[themeStyle[theme].description, tw`mt-2 lg:mt-1`]} />
        </div>
        <Button
          type="primary"
          size="base"
          theme={themeStyle[theme].button}
          label={callToAction.label}
          link={callToAction.link}
          style={tw`mt-6 lg:(mt-0 flex-none)`}
        />
      </div>
    </div>
  )
}

CallToAction.propTypes = {
  title: PropTypes.string,
  description: HTMLType,
  callToAction: RequiredLinkType,
  theme: PropTypes.oneOf(['navy', 'cyan']),
  style: StyleType,
}

export default CallToAction
