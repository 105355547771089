import groupBy from 'lodash/groupBy'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { styled } from 'twin.macro'
import Accordion from './accordion'
import Heading from './heading'
import Text, { inlineTypography, emailStyle } from './text'

const Location = styled.div`
  ${tw`flex flex-col items-start lg:col-span-4`}
`

const LocationGroup = styled.div`
  ${tw`flex flex-col w-full space-y-20 lg:(grid grid-cols-12 gap-default space-y-0 gap-y-24 gap-x-8) xl:(w-3/4 self-end)`}
`

const Locations = ({ headquarters, offices, headquartersLabel, officesLabel }) => {
  const regions = groupBy(offices, (o) => o.region)

  return (
    <div css={tw`flex flex-col`}>
      <div css={tw`flex flex-col items-start mb-20 lg:mb-32`}>
        <Heading
          headingType="h4"
          content={headquartersLabel}
          style={tw`text-primary-500 mb-10 lg:mb-8`}
        />
        <LocationGroup>
          {headquarters &&
            headquarters.map((hq) => (
              <Location key={hq.title} id={kebabCase(hq.title)}>
                <Heading
                  headingType="h6"
                  content={hq.title}
                  style={tw`text-primary-500 font-medium mb-8`}
                />
                <Text content={hq.parentSiteAddressNode} style={inlineTypography} />
              </Location>
            ))}
        </LocationGroup>
      </div>
      <div>
        <Heading
          headingType="h4"
          content={officesLabel}
          style={tw`text-primary-500 mb-10 lg:mb-8`}
        />
        <Accordion>
          {map(regions, (region, label) => (
            <LocationGroup key={label}>
              {region.map((office) => (
                <Location key={office.title} id={kebabCase(office.title)}>
                  <Heading
                    headingType="h6"
                    content={office.title}
                    style={tw`text-primary-500 font-medium mb-8`}
                  />
                  <Text content={office.parentSiteAddressNode} style={emailStyle} />
                </Location>
              ))}
            </LocationGroup>
          ))}
        </Accordion>
      </div>
    </div>
  )
}

Locations.propTypes = {
  headquarters: PropTypes.array,
  offices: PropTypes.array,
  headquartersLabel: PropTypes.string,
  officesLabel: PropTypes.string,
}

export default Locations
