import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import Heading from './heading'

const Hero = ({ style, title, description }) => {
  return (
    <div
      css={[
        global`layout.container`,
        global`layout.grid`,
        tw`w-full bg-transparent py-20 lg:py-32 `,
        style,
      ]}
    >
      <Heading
        content={title}
        headingType="h2"
        style={tw`row-start-1 font-bold text-primary-500 col-span-4 md:col-span-6 lg:col-span-8`}
      />
      {description && (
        <Heading
          content={description}
          headingType="h5"
          style={tw`row-start-2 pt-6 font-normal text-grey col-span-4 md:col-span-6 lg:col-span-8`}
        />
      )}
    </div>
  )
}

Hero.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }).isRequired,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }),
  ]),
}

export default Hero
