import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Link from './link'
import Text from './text'

const ContactTabs = ({ tabs, style }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].title)
  const inactive = css`
    ${tw`w-full py-4 px-4 lg:py-7 bg-secondary-500 text-primary-500`}
  `
  const active = css`
    ${tw`w-full text-white pt-7 pb-4 px-4 lg:(pt-11 pb-7) bg-primary-500`}
  `

  return (
    <div css={[tw`flex flex-col w-full`, style]}>
      <ul css={tw`flex flex-row items-end`}>
        {tabs.map((tab) => (
          <li key={tab.title} css={tw`flex-1`}>
            <button
              type="button"
              css={[
                tab.title === selectedTab ? active : inactive,
                tabs.length === 1 && tw`pt-14 lg:pt-20`,
              ]}
              onClick={() => setSelectedTab(tab.title)}
            >
              <Heading content={tab.title} headingType="h4" style={tw`font-bold text-center`} />
            </button>
          </li>
        ))}
      </ul>
      {tabs.map((tab) => (
        <div
          key={tab.title}
          css={[
            tw`flex-col bg-primary-500 py-14 px-6 lg:(py-20 px-8)`,
            tab.title !== selectedTab && tw`hidden`,
            tabs.length === 1 && tw`pt-1 lg:(pt-1)`,
          ]}
        >
          <Heading
            content={tab.description}
            headingType="h5"
            style={tw`text-center text-white text-opacity-60 mb-14 lg:mb-20`}
          />
          <div css={tw`flex flex-col space-y-14 lg:(flex-row justify-around space-y-0 space-x-6)`}>
            {tab.contacts.map((contact) => {
              const valueComponent = () => {
                switch (contact.contactType) {
                  case 'URL':
                    return (
                      <Button
                        link={contact.value}
                        label={contact.label}
                        type="primary"
                        size="base"
                        theme="white-cyan"
                        style={tw`bg-primary-50 text-primary-500`}
                        hideCaret
                      />
                    )
                  case 'Email':
                    return (
                      <Link
                        to={`mailto:${contact.value}`}
                        style={global`base.hover-underline`(tw`bg-white`)}
                      >
                        <Heading
                          content={contact.value}
                          headingType="h4"
                          style={tw`text-white font-medium`}
                        />
                      </Link>
                    )
                  case 'Text':
                  default:
                    return (
                      <Heading
                        content={contact.value}
                        headingType="h4"
                        style={tw`text-white font-medium`}
                      />
                    )
                }
              }

              return (
                <div
                  key={contact.label}
                  css={css`
                    ${tw`flex flex-col flex-auto text-center items-center`};
                  `}
                >
                  <Text
                    content={contact.label}
                    style={tw`text-base font-bold text-center text-secondary-500 mb-7`}
                  />
                  {valueComponent()}
                  {contact.subtextNode && (
                    <Text
                      content={contact.subtextNode}
                      style={(global`typography.subtitle`, tw`text-white text-opacity-37 mt-4`)}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}

ContactTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: HTMLType,
      description: HTMLType,
      contacts: PropTypes.arrayOf(PropTypes.object),
    }).isRequired
  ),
  style: StyleType,
}

export default ContactTabs
